.connect-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.connect-container {
    display: flex;
    width: 875px;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: black;
}

.connect-form-group {
    display: flex;
    width: 95%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    gap: 50px;
}

.connect-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    gap: 15px;
}

.connect-form-header-title {
    color: #FFF;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
    letter-spacing: -2px;
    text-align: center;
}

.connect-form-header-subtitle {
    width: 875px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

.connect-form {
    display: flex;
    padding: 0px 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    align-self: stretch;
}

.connect-form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.connect-form-field-header {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.connect-form-field-title {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 100% */
    letter-spacing: -0.6px;
    
    flex: 1;
}

.connect-form-field-error {
    color: red;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 100% */
    letter-spacing: -0.6px;

    flex: 1;
    text-align: right;
}

.connect-form-field-input {
    align-self: stretch;
    color: black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 100% */
    letter-spacing: -1px;
    padding: 10px 10px;
}

.connect-form-field-input[type="text"],
.connect-form-field-input[type="email"] {
    resize: none;
}

.connect-form-field-input[type="textarea"],
textarea.connect-form-field-input {
    resize: vertical;
    min-height: 130px;
}

.connect-form-field-line {
    align-self: stretch;
    height: 1px;
    background: white;
}

.connect-form-button-container {
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .connect-content-mobile {
        display: flex;
        width: 100%;
        height: calc(100vh - 72px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .connect-form-group-mobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        gap: 50px;
    }

    .connect-form-header-mobile {
        display: flex;
        width: 100%;
        padding: 0px 25px;
        box-sizing: border-box;
        flex-direction: column;
        align-items: left;
        gap: 12.5px;
        /* align-self: stretch; */
    }
    
    .connect-form-header-title-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 100% */
        letter-spacing: -1px;
    }
    
    .connect-form-header-subtitle-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 100% */
        letter-spacing: -0.6px;
    }

    .connect-form-header-line-mobile {
        width: 100%;
        height: 1px;
        background: #FFF;
    }

    .connect-form-mobile {
        display: flex;
        padding: 0px 25px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        align-self: stretch;
    }

    .connect-form-field-input {
        align-self: stretch;
        color: black;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        padding: 10px 10px;
        line-height: 15px; /* 100% */
        letter-spacing: -1px;
    }
}