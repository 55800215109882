.careers-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
    position: relative;
    z-index: 1;
}

.careers-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    gap: 30px;
}

.careers-header-title {
    /* width: 1000px; */
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
    letter-spacing: -2px;
}

.careers-header-subtitle {
    /* width: 900px; */
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

.careers-container {
    display: flex;
    /* max-width: 1500px; */
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 50px 250px;
    flex-wrap: wrap;
}

.careers-group {
    display: flex;
    width: 500px;
    height: 210px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
}

.careers-group-line {
    width: 100%;
    height: 1px;
    background: #FFF;
}

.careers-group-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;   
}

.careers-group-header-left {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.careers-group-header-right {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    cursor: pointer;
}

.careers-group-header-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

.careers-group-header-icon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
}

.careers-group-title {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
    letter-spacing: -1.4px;
}

.careers-group-body {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

@media (max-width: 768px) {
    .careers-content-mobile {
        display: flex;
        width: 100%;
        /* height: calc(100vh - 72px); */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .careers-header-mobile {
        display: flex;
        width: 100%;
        padding: 50px 25px;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        gap: 80px;
    }

    .careers-header-content-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12.5px;
        align-self: stretch;
    }

    .careers-header-title-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 100% */
        letter-spacing: -1px;
    }

    .careers-header-subtitle-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 100% */
        letter-spacing: -0.6px;
    }

    .careers-header-line-mobile {
        width: 100%;
        height: 1px;
        background: #FFF;
    }

    .careers-container-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 100px;
        flex-direction: column;
    }

    .careers-group-mobile {
        display: flex;
        width: 100%;
        /* height: 280px; */
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        flex-shrink: 0;
    }
    
    .careers-group-line-mobile {
        width: 100%;
        height: 1px;
        background: #FFF;
    }
    
    .careers-group-header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;   
    }
    
    .careers-group-header-left-mobile {
        display: flex;
        align-items: flex-start;
        gap: 5px;
    }
    
    .careers-group-header-right-mobile {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        cursor: pointer;
    }
    
    .careers-group-header-text-mobile {
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.6px;
    }
    
    .careers-group-header-icon-mobile {
        width: 18px;
        height: 18px;
        vertical-align: middle;
    }
    
    .careers-group-title-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 114.286% */
        letter-spacing: -1.4px;
    }
    
    .careers-group-body-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.6px;
    }
}