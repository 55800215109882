.products-category-content {
    display: flex;
    padding: 138px 100px; /* padding: 138px 100px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-shrink: 0;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.products-category-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    align-self: stretch;
}

.products-category-header-title {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 100% */
    letter-spacing: -2px;
}

.products-category-header-subtitle {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 120% */
    letter-spacing: -1px;
}

.products-category-header-line {
    width: 100%;
    height: 1px;
    background: #FFF;
}

.products-category-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: 100px;
    align-self: stretch;
    flex-wrap: wrap;
}

.products-category-product {
    display: flex;
    width: 480px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;   
}

.products-category-product-showcase {
    position: relative;
    width: 480px;
    height: 392.621px;
}

.products-category-product-showcase-frame-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    align-self: stretch;
}

.products-category-product-showcase-frame.desktop-device {
    width: 480px;
    height: 392.621px;
}

.products-category-product-showcase-frame.mobile-device {
    width: 194.207px;
    height: 392.564px;
}

.products-category-product-showcase-screen.desktop-device {
    position: absolute;
    width: 450px;
    height: 251px;
    flex-shrink: 0;
    top: 16.31px;
    left: 16.31px;
}

.products-category-product-showcase-screen.mobile-device {
    position: absolute;
    width: 171.799px;
    height: 371.815px;
    flex-shrink: 0;
    top: 10.37px;
    left: 154.1px;
    border-radius: 15px;
}

.products-category-product-line {
    width: 100%;
    height: 1px;
    background: white;
}

.products-category-product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.products-category-product-header-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.6px;
}

.products-category-product-header-link {
    display: flex;
    align-items: center;
    gap: 5px;
}

.products-category-product-header-link-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: -0.6px;
}

.products-category-product-header-link-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.products-category-product-title {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
    letter-spacing: -1.4px;
}

.products-category-product-body {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

.standalone-image img {
    width: 100%; 
    height: auto;
}

@media (max-width: 768px) {
    .products-category-content-mobile {
        width: 100%;
        /*height: 100vh;
        flex-shrink: 0; */
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .products-category-body-mobile {
        display: flex;
        width: 100%;
        /* height: 100vh; */
        /* padding: 50px 25px; */
        padding-bottom: 50px;
        /* box-sizing: border-box; */        
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .products-category-section-header-mobile {
        display: flex;
        width: 100%;
        padding: 50px 25px;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .products-category-section-header-content-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12.5px;
        align-self: stretch;
    }

    .products-category-section-header-title-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 100% */
        letter-spacing: -1px;
    }

    .products-category-section-header-subtitle-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 100% */
        letter-spacing: -0.6px;
    }

    .products-category-section-header-line-mobile {
        width: 100%;
        height: 1px;
        background: #FFF;
    }

    .products-category-section-item-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        align-self: stretch;
        padding-left: 25px;
        padding-right: 25px;
    }

    .products-category-section-item-content-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
    }

    .products-category-product-item-showcase-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 280px;
        align-self: stretch;
    }

    .products-category-product-item-showcase-frame-container-mobile {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    
    .products-category-product-item-showcase-frame-mobile.desktop-device {
        width: 340px;
        height: 100%;
        flex-shrink: 0;
    }
    
    .products-category-product-item-showcase-frame-mobile.mobile-device {
        width: 135px;
        height: 100%;
    }
    
    .products-category-product-item-showcase-screen-mobile.desktop-device {
        position: absolute;
        width: 81%;
        left: 50px;
        height: 180px;
        flex-shrink: 0;
        left: 37.5px;
        transform: translateY(10.5px);
    }
    
    .products-category-product-item-showcase-screen-mobile.mobile-device {
        position: absolute;
        width: 92.5%;
        height: 96%;
        flex-shrink: 0;
        border-radius: 15px;
    }

    .products-category-section-item-line-mobile {
        width: 100%;
        height: 1px;
        background: white;
        margin-top: 10px;
    }

    .products-category-section-item-header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    .products-category-section-item-header-tag-mobile {
        color: #FFF;
        font-family: Poppins;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 120% */
        letter-spacing: -0.5px;
    }

    .products-category-section-item-header-link-mobile {
        display: flex;
        align-items: flex-start;
        gap: 5px;
    }

    .products-category-section-item-header-link-text-mobile {
        color: #FFF;
        font-family: Poppins;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px; /* 120% */
        letter-spacing: -0.5px;
    }

    .products-category-section-item-header-link-icon-mobile {
        width: 14px;
        height: 14px;
        vertical-align: middle;
    }

    .products-category-section-item-title-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 80% */
        letter-spacing: -1px;
    }

    .products-category-section-item-body-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 100% */
        letter-spacing: -0.6px;
    }
}