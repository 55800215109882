.group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;
    max-width: 400px;

    font-family: Poppins;
    letter-spacing: -4%;
    font-style: normal;
}

.group-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.group-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    vertical-align: middle;
}

.group-title {
    font-size: 25px;
    line-height: 40px;
    opacity: 0.5;
    font-weight: normal;
    transition: opacity 0.3s ease;
}

.group-title.selected {
    opacity: 1;
    font-weight: bold;
}

.group-body {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
}

.group-body.selected {
    max-height: 300px;
    opacity: 1;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

.group-body.previouslySelected {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

@media (max-width: 768px) {
    .group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
    }

    .group-title {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 160% */
        letter-spacing: -1px;
    }

    .group-title.selected {
        font-weight: 700;
    }

    .group-body {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.6px;
    }
}