.confirm-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.confirm-container {
    display: flex;
    width: 875px;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: black;
}

.confirm-line {
    width: 1px;
    height: 100%;
    background: white;
}

.confirm-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    gap: 15px;
    width: 90%;
}

.confirm-header-title {
    color: #FFF;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
    letter-spacing: -2px;
    text-align: center;
}

.confirm-header-subtitle {
    width: 875px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

.confirm-logo {
    height: 200px;
    flex-shrink: 0;
    margin-bottom: 50px;
}