.splash-content {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: #000;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.splash-logo {
    width: 433px;
    height: 86px;
    flex-shrink: 0;
}

@media (max-width: 768px) {
    .splash-content {
        display: flex;
        width: 100%;
        height: 100vh;
        /* padding: 0px 58px; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .splash-logo {
        width: 274px;
        height: 54px;
        flex-shrink: 0;
    }
}