.navbar {
    position: relative;
    display: flex;
    background-color: white;
    font-family: 'Poppins';
    font-weight: 200;
    font-size: 0.8rem;
    flex-shrink: 0;
    line-height: 1.5em;
    width: 100%;
}

.navbar-menu-icon {
    display: none;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    margin: auto;
}

.navbar-left,
.navbar-centre,
.navbar-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-left {
    justify-content: flex-start; 
}

.navbar-right {
    justify-content: flex-end;
}

.navbar-logo {
    width: 11rem;
    height: auto;
    vertical-align: middle;
}

.navbar-text {
    width: 500px;
    height: 40px;
    color: black;
    text-align: center;
    font-family: Poppins;
    /* font-size: 13px; */
    font-style: normal;
    font-weight: 200;
    line-height: 20px; /* 133.333% */
    /* letter-spacing: -0.6px; */
}

.navbar-menu {
    margin-left: auto;
    display: flex;
    align-items: center;
    list-style: none;
}

.navbar-item {
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 266.667% */
    letter-spacing: -0.6px;
}

.navbar-item:hover {
    font-weight: 400;
}

.navbar-item.active, .navbar-item.active:hover {
    font-weight: 600;
}

.active {
    font-weight: 600;
}

.navbar-social-container {
    display: flex;
    padding: 15px 0px;
    align-items: center;
    gap: 30px;
}

.navbar-social-icon {
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .navbar-mobile {
        flex-shrink: 0;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .navbar-mobile-top {
        display: flex;
        height: 72px;
        align-items: center;
        flex-shrink: 0;
        background: white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 2;
    }

    .navbar-mobile-top-container {
        display: flex;
        width: 100%;
        padding: 11px 25px;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-mobile-top-logo {
        width: 150px;
        height: 30px;
        flex-shrink: 0;
        vertical-align: middle;
    }

    .navbar-mobile-top-icon {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
    }

    .navbar-menu {
        height: calc(100vh - 72px);
        width: 100vw;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-shrink: 0;
        background: white;
    }

    .navbar-menu-items {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
        padding: 50px 25px;
        box-sizing: border-box;
    }
    
    .navbar-item {
        cursor: pointer;

        color: black;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px; /* 160% */
        letter-spacing: -1px;
    }

    .navbar-item:hover {
        font-weight: 400;
    }
    
    .navbar-item.active, .navbar-item.active:hover {
        font-weight: 600;
    }

    .navbar-social-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        gap: 25px;
        padding: 50px 35px;
        box-sizing: border-box;
    }
    
    .navbar-social-icon {
        width: 30px;
        height: 30px;
    }
}