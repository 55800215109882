.product-content {
    display: flex;
    height: 100vh;
    padding: 138px 100px; /* padding: 138px 100px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-shrink: 0;
    position: relative;
}

.product-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    align-self: stretch;
}

.product-header-title {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 100% */
    letter-spacing: -2px;
}

.product-header-subtitle {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 120% */
    letter-spacing: -1px;
}

.product-header-line {
    width: 100%;
    height: 1px;
    background: white;
}