.company-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: -20px;
}

.company-left-content {
    display: flex;
    width: 55%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.company-right-content {
    display: flex;
    width: 45%;
    height: 100vh;
    /* padding: 334px 126px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 30px;
}

.company-line {
    position: absolute;
    width: 1px;
    height: 100vh;
    left: 1044px;
    background: white;
}

.company-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 420px;

    font-family: Poppins;
    color: white;
}

.company-options-header {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 40px;
    color: white;

    display: flex;
    align-items: center;
    gap: 10px;

    cursor: pointer;
    opacity: 0.5;
    font-weight: normal;
    transition: opacity 0.3s ease, font-weight 0.1s ease;
}

.company-options-header.selected {
    opacity: 1;
    font-weight: bold;
}

.company-options-header-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    vertical-align: middle;
}

.company-options-header-text {
    font-size: 25px;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -4%;
    z-index: 2;
}

.company-options-body {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
}

.company-options-body.selected {
    max-height: 300px;
    opacity: 1;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}