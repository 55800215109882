.footer {
    position: relative;
    display: flex;
    align-items: center;
    background-color: black;
    color: #fff;
    font-family: 'Poppins';
    font-weight: 200;
    font-size: 0.8rem;
    padding: 2rem;
    line-height: 1.5em;
}

.footer-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: white;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    /* flex-wrap: wrap; */
}

.footer-left,
.footer-centre,
.footer-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin: 5px; */
}

.footer-left {
    justify-content: flex-start; 
}

.footer-right {
    justify-content: flex-end;
}

.footer-logo {
    width: 11rem;
    height: auto;
    vertical-align: middle;
}

.footer-text {
    width: 500px;
    height: 40px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    /* font-size: 13px; */
    font-style: normal;
    font-weight: 200;
    line-height: 20px; /* 133.333% */
    /* letter-spacing: -0.6px; */
}

.footer-social-container {
    display: flex;
    /* padding: 15px 0px; */
    align-items: center;
    gap: 30px;
}

.footer-social-icon {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    /* margin: 0 5px; */
}

@media (max-width: 768px) {
    .footer {
        font-size: 0.6rem;
    }

    .footer-content {
        flex-direction: column;
    }

    .footer-left, .footer-centre, .footer-right {
        margin: 10px 0;
    }

    .footer-logo {
        width: 9rem;
    }

    .footer-text {
        width: 100%;
    }

    .footer-social-icon {
        width: 20px;
        height: 20px;
    }
}