.company-page-2-content {
    display: flex;
    height: 100vh;
    padding: 0px 460px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 80px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
}

.company-page-2-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    flex-shrink: 0;
    align-self: stretch;
    gap: 30px;
}

.company-page-2-header-title {
    /* width: 1000px; */
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
    letter-spacing: -2px;
}

.company-page-2-header-subtitle {
    /* width: 900px; */
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

.company-page-2-team {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.company-page-2-team-option {
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 155px;
    color: #FFF;
    text-align: center;
    font-family: Poppins; 
    cursor: pointer;

    height: 40px;
    line-height: 40px;
    font-size: 25px;
    font-weight: normal;
    letter-spacing: -1px;
    transition: font-size 0.1s ease, font-weight 0.1s ease, letter-spacing 0.1s ease;
}

.company-page-2-team-option.selected {
    font-size: 35px;
    font-weight: bold;
    letter-spacing: -1.4px;
}

.company-page-2-card {
    /* width: 1000px; */
    height: 350px;
    flex-shrink: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.company-page-2-card-background {
    /* width: 1000px; */
    height: 350px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FFF;
    display: flex;
}

.company-page-2-card-image {
    /* width: 280px; */
    height: 296px;
    flex-shrink: 0;
    border-radius: 30px 0px 0px 30px;
    align-self: center;
    margin-left: 30px;
    /* box-shadow: -5px 0px 4px 0px rgba(0, 0, 0, 0.25) inset; */
}

.company-page-2-card-content {
    display: flex;
    /* width: 697px; */
    padding: 30px;
    
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.company-page-2-card-content-group {
    display: flex;
    width: 617px;
    /* height: 289px; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 23px;
    left: 30px;
    top: 30px;
    margin-right: 30px;
    /* background: red; */
}

.company-page-2-card-content-group-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.company-page-2-card-content-group-header-text {
    flex: 1 0 0;
    align-self: stretch;
    color: #000;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

.company-page-2-card-content-group-header-icon {
    width: 30px;
    height: 30px;
}

.company-page-2-card-content-group-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.company-page-2-card-content-group-body-left {
    flex: 1 0 0;
    align-self: stretch;
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

.company-page-2-card-content-group-body-right {
    flex: 1 0 0;
    align-self: stretch;
    color: #000;
    text-align: right;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

.company-page-2-card-content-group-body-bottom {
    align-self: stretch;
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

@media (max-width: 768px) {
    .company-page-2-content-mobile {
        display: flex;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
        gap: 50px;
        z-index: 1;
        position: relative;
    }

    .company-page-2-header-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
    }

    .company-page-2-header-title-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; 
        letter-spacing: -1px;
        text-align: left;
    }
    
    .company-page-2-header-subtitle-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 
        letter-spacing: -0.6px;
        text-align: left;
    }

    .company-page-2-team-mobile {
        display: flex;
        width: 100%;
        height: 40px;
        justify-content: space-between;
        align-items: center;
    }
    
    .company-page-2-team-option-mobile {
        display: flex;
        align-items: center;
        justify-content: center;    
        color: #FFF;
        text-align: center;
        font-family: Poppins; 
        cursor: pointer;
    
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: -0.6px;
        transition: font-size 0.1s ease, font-weight 0.1s ease, letter-spacing 0.1s ease;
    }
    
    .company-page-2-team-option-mobile.selected {
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; 
        letter-spacing: -1px;
    }

    .company-page-2-card-content-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .company-page-2-card-content-group-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .company-page-2-card-content-group-body-mobile {
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        line-height: 20px; 
        letter-spacing: -0.6px;
    }

    .company-page-2-card-image-mobile {
        width: 135px;
        height: 142px;
        position: absolute;
        right: 25px;
        background: white;
        border-radius: 15px;
        border: 1px solid #FFF;
    }
}