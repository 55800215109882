.products-hero-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
    position: relative;
    z-index: 1;
}

.products-hero-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    gap: 30px;
}

.products-hero-header-title {
    /* width: 1000px; */
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
    letter-spacing: -2px;
}

.products-hero-header-subtitle {
    /* width: 900px; */
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

@media (max-width: 768px) {
    .products-hero-content {
        width: 100%;
        height: calc(100vh - 72px);
        flex-shrink: 0;
    }

    .products-hero-header {
        display: flex;
        /* width: 100%; */
        height: 350px;
        padding-left: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 125px;
        flex-shrink: 0;
    }

    .products-hero-header-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
    }

    .products-hero-header-title {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 160% */
        letter-spacing: -1px;
        text-align: left;
    }

    .products-hero-header-subtitle {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.6px;
        text-align: left;
    }

    .products-hero-logo-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
    }

    .products-hero-logo {
        width: 365px;
        height: 262px;
        flex-shrink: 0;
    }

    .products-hero-line {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: white;
    }
}