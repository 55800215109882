.company-page-1-content {
    position: relative;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.company-page-1-left-content {
    display: flex;
    width: 55%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.company-page-1-right-content {
    display: flex;
    width: 40%;
    height: 100vh;
    justify-content: left;
    align-items: center;
    flex-shrink: 0;
}

.company-page-1-grouped-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
}

.company-page-1-grouped-content {
    display: flex;
    width: 478px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    flex-shrink: 0;
}

.company-page-1-grouped-content-header {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
    letter-spacing: -1.4px;
}

.company-page-1-grouped-content-body {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

.company-page-1-grouped-content-project-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    min-width: 0;
    z-index: 10;
}

.company-page-1-grouped-content-project {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 5px;
    position: relative;
    width: 186px;
    z-index: 11;
    cursor: pointer;
}

.company-page-1-grouped-content-project-text {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.6px;
    z-index: 12;
}

.company-page-1-grouped-content-project-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.company-page-1-logo {
    height: 350px;
    flex-shrink: 0;
}

@media (max-width: 768px) {
    .company-page-1-content-mobile {
        display: flex;
        width: 100%;
        height: calc(100vh - 72px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    
    .company-page-1-centre-content-mobile {
        display: flex;
        width: 100%;
        padding: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 25px;
    }

    .company-page-1-grouped-content-mobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 25px;
        padding: 25px;
        box-sizing: border-box;
    }

    .company-page-1-grouped-content-header-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -1px;
    }
    
    .company-page-1-grouped-content-body-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.6px;
    }
}