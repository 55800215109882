.services-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.services-left-content {
    display: flex;
    width: 55%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.services-right-container {
    display: flex;
    width: 45%;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: black;  
}

.services-right-content {
    display: flex;
    width: 99%;
    /* padding: 334px 126px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
}

.services-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    min-width: 600px;
}

.services-options-header {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 40px;
    color: white;

    font-family: Poppins;
    font-size: 50px;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -2px;
    z-index: 2;

    cursor: pointer;
    opacity: 0.5;
    font-weight: normal;
    transition: opacity 0.3s ease, font-weight 0.1s ease;
}

.services-options-header.selected {
    opacity: 1;
    font-weight: bold;
}

.services-grouped-content {
    display: flex;
    width: 478px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-shrink: 0;
}

.services-grouped-content-header {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
    letter-spacing: -1.4px;
}

.services-grouped-content-body {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

.services-grouped-content-project-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    min-width: 0;
    z-index: 10;
}

.services-grouped-content-project {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 5px;
    position: relative;
    width: 186px;
    z-index: 11;
    cursor: pointer;
}

.services-grouped-content-project-text {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.6px;
    z-index: 12;
}

.services-grouped-content-project-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.verification-image {
    max-width: 100px;
    height: auto;
    cursor: pointer;
}

@media (max-width: 768px) {
    .services-content {
        display: flex;
        width: 100%;
        height: calc(100vh - 72px);
        /* padding: 196px 0px; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .services-centre-content {
        display: flex;
        width: 100%;
        padding: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 25px;
    }

    .services-grouped-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 25px;
        padding: 25px;
        box-sizing: border-box;
    }

    .verification-image {
        max-width: 60px;
        height: auto;
        cursor: pointer;
    }
}