.company-page-3-content {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 150px;
    flex-shrink: 0;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
}

.company-page-3-group {
    display: flex;
    height: 150px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.company-page-3-group-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.company-page-3-group-header-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.company-page-3-group-header-text {
    width: 350px;
    color: #FFF;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 160% */
    letter-spacing: -1px;
}

.company-page-3-group-body {
    width: 350px;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.6px;
}

@media (max-width: 768px) {
    .company-page-3-content-mobile {
        display: flex;
        width: 100%;
        /* height: calc(100vh - 72px); */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .company-page-3-centre-content-mobile {
        display: flex;
        width: 100%;
        padding: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 25px;
    }
    
    .company-page-3-group {
        display: flex;
        height: 150px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .company-page-3-grouped-content-mobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 25px;
        padding: 25px;
        box-sizing: border-box;
    }

    .company-page-3-grouped-content-header-mobile {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .company-page-3-grouped-content-header-mobile-text {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 160% */
        letter-spacing: -1px;
    }
    
    .company-page-3-grouped-content-body-mobile {
        align-self: stretch;
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.6px;
    }
}